import { AyudaService } from '../../services/ayuda.service';
import {
  AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, Inject, OnDestroy, ViewChild, ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface AyudaModalContent {
  component: any;
  hideButton?: boolean;
}

@Component({
  selector: 'app-modal-ayuda',
  templateUrl: './modal-ayuda.component.html',
  styleUrls: ['./modal-ayuda.component.scss']
})
export class ModalAyudaComponent implements OnDestroy, AfterViewInit {
  @ViewChild('helpModalContent', { read: ViewContainerRef }) entry: ViewContainerRef;

  componentRef: ComponentRef<any>;

  constructor(
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: AyudaModalContent,
    private ayudaService: AyudaService
  ) { }

  ngOnDestroy() {
    this.componentRef?.destroy();
  }

  ngAfterViewInit() {
    this.createComponent();
  }

  closeModal(role = 'closed') {
    this.ayudaService.closeModal(role);
  }

  private createComponent() {
    if (!this.data) return;

    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.entry.createComponent(factory);
  }
}
