import { ModalAyudaComponent } from '../components/modal-ayuda/modal-ayuda.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AyudaService {
  private modalRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog) { }

  /**
   * Opens a new modal with a component
   * @param component the component to render inside the modal
   * @returns the reference to the opened modal
   */
  showHelpModal(component: any, hideButton?: boolean) {
    this.modalRef = this.dialog.open(ModalAyudaComponent, {
      maxHeight: '90vh',
      autoFocus: false,
      id: 'modal-ayuda',
      data: { component, hideButton },
      panelClass: 'no-min-width'
    });

    return this.modalRef;
  }

  closeModal(role: string) {
    this.modalRef?.close({ role });
  }
}
